








import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Line } from '@antv/g2plot'
import DataSet from '@antv/data-set'

@Component({
  name: 'LineChart'
})
export default class LineChart extends Vue {
  @Prop({
    type: Array,
    default() {
      return []
    }
  })
  readonly data!: []
  @Prop({
    type: Object,
    default() {
      return {}
    }
  })
  readonly config!: any
  @Prop({
    type: Array,
    default() {
      return []
    }
  })
  readonly fields!: []
  @Prop({
    type: Object,
    default() {
      return {}
    }
  })
  readonly aliases?: any
  @Prop({ type: [String, Number], default: 'auto' }) readonly height!: any
  @Prop({ type: Boolean, default: false }) readonly needUpdateConfig?: boolean
  @Ref('ChartContainer') readonly ChartContainer!: HTMLElement

  private line: any

  private get _height() {
    return isNaN(this.height) ? this.height : `${this.height}px`
  }

  @Watch('data', { deep: true, immediate: true })
  dataChange(v: any) {
    if (!this.line) {
      return
    }

    if (this.needUpdateConfig) {
      this.line.update({
        ...this.config,
        data: this.transformData(v)
      })
      this.line.render()
    } else {
      this.line.changeData(this.transformData(v))
    }
  }

  mounted() {
    this.line = new Line(this.ChartContainer, {
      data: this.transformData(this.data),
      xField: 'date',
      yField: 'value',
      legend: {
        position: 'bottom',
        itemHeight: 30
      },
      tooltip: {
        domStyles: {
          'g2-tooltip-marker': {
            width: '5px',
            height: '5px'
          }
        },
        formatter: (datum) => {
          const nameKey = this.config?.seriesField || 'key'
          const valueKey = this.config?.yField || 'value'
          return {
            name: datum[nameKey],
            value: datum[valueKey]?.toLocaleString()
          }
        }
      },
      color: [
        '#1C9AEE',
        '#09C18B',
        '#F38080',
        '#FFC34C',
        '#30D4D4',
        '#BC8DFB',
        '#A4DBFF',
        '#E591DA'
      ],
      ...this.config
    })

    this.line.render()
  }

  private transformData(v: []) {
    const ds = new DataSet()
    const dv = ds.createView().source(v)
    const retains = this.config.foldRetains || [this.config.xField || 'date']
    const yField = this.config.yField || 'value'
    const key = this.config.foldKey || 'key'
    const aliases = { ...this.aliases }

    // 参数含义及效果详见 https://g2.antv.vision/zh/docs/manual/dataset/transform#fold-%E5%AD%97%E6%AE%B5%E5%B1%95%E5%BC%80
    dv.transform({
      type: 'fold',
      fields: this.fields, // 展开字段集
      key: key, // key字段
      value: yField, // value字段
      retains: retains // 保留字段集，默认为除 fields 以外的所有字段
    }).transform({
      type: 'map',
      callback(row) {
        if (aliases) {
          // 图例别名
          row[key] = aliases[row[key]]
        }
        return row
      }
    })

    return dv.rows
  }
}
